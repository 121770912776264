import React, { useState } from 'react';

import styled from 'styled-components';
import tw from 'tailwind.macro';
import { useSpring, animated, interpolate } from 'react-spring';
import Img from 'gatsby-image';
import { navigate } from 'gatsby';
import ReactPlayer from 'react-player';
import { isMobile } from 'react-device-detect';

import CustomLink from './CustomLink'
import PlayButton from '../svg/PlayButton';

const Wrapper = styled(animated.div)`
    // ${tw`relative z-0 overflow-hidden my-4 w-full cursor-pointer`};
    -webkit-tap-highlight-color: rgba(0,0,0,0);
`
const ImgWrapper = styled(animated.div)`
    ${tw`relative z-0 overflow-hidden my-8 w-full cursor-pointer`};
    // ${props => props.small ? 'height: 15rem;' : 'height: 72vh;'};    
    border-radius: 0.809rem;
`

const Description = styled.p`
    ${tw`text-base text-grey-light ml-3`};
`

const ClientLogo = styled.img`
    ${tw`h-12 ml-2`};
`
const Paragraph = styled.p`
      ${tw`font-secondary text-grey my-6`};
    letter-spacing: 0.006em;
    @media(min-width: 768px){
      font-size: 1rem;
    }
    font-size: 1.414rem;
    line-height: 1.618;   
`
const AnimatedImg = animated(Img);

export default function FeaturedWorkCard(props) {
    const [hovering, setHovering] = useState(false);
    const [isClicked, setClicked] = useState(false);

    const styles = useSpring({ s: hovering ? 0.98 : 1, imgS: hovering ? 1.1 : 1, });

    function hoverHandle(bool) {
        setHovering(bool);
    }

    const handleClick = () => {
        if (props.isComingSoon) {
            console.log('Is Coming Soon');
            // setClicked(state => !state);
            setClicked(true);
        }
        else {
            navigate(`/work/${props.to}/`);
        }
    }
    return (

        <Wrapper onMouseEnter={() => hoverHandle(true)}
            onMouseLeave={() => hoverHandle(false)}
            onTouchStart={() => hoverHandle(true)}
            onTouchEnd={() => hoverHandle(false)}
            onClick={() => handleClick()}>

            <ImgWrapper
                style={{
                    transform: styles.s.interpolate(s => `scale(${s})`),
                    transformOrigin: 'center center',
                }}
                small={props.small}>

                <div css={`${tw`flex items-center justify-center absolute justify-center z-20 h-full w-full pin-l pin-t`};`}>
                    {(isMobile && props.hasThumbnailVideo) && <PlayButton onClick={() => console.log('Play the Video Now')} />}
                    {isClicked && <p css={`${tw`text-white font-lg font-bold`}`}>Coming Soon!</p>}
                </div>

                {(props.hasThumbnailVideo) ?
                    <ReactPlayer
                        url={props.thumbnailVideoUrl}
                        playing={hovering || isMobile}
                        muted
                        loop
                        playsinline
                        // width={400}
                        // height={'100%'}
                        style={{ position: 'relative', borderRadius: '0.8rem', objectFit: 'cover', }}
                    /> :

                    <AnimatedImg fixed={props.thumbnail}
                        alt={`${props.title} (www.clearcut.design)`}
                        style={{
                            borderRadius: '1rem',
                            // transform: styles.imgS.interpolate(s => `scale(${s})`),
                            transformOrigin: 'center center',
                            // boxShadow: '0 20px 30px hsla(234, 10%, 90%, 0.9 )',

                        }} />

                }
            </ImgWrapper>

            <div css={tw`w-4/5`}>
                <ClientLogo src={props.clientLogo} />
                <Paragraph css={tw`ml-4`}>{props.description}</Paragraph>
            </div>

        </Wrapper>
    );
}