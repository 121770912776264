import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import axios from 'axios';
import addToMailchimp from 'gatsby-plugin-mailchimp';

import ColorfulCircle from '../svg/ColorfulCircle'

const Form = styled.form`
    ${tw`relative flex flex-wrap w-full`};

`
const Label = styled.label`
    ${tw`font-serif font-light text-xl ml-2`};
`

const InputWrapper = styled.div`
    ${tw`flex items-center relative z-0 mt-4 w-full`};
    height: 5rem;
`

const EmailInput = styled.input`
    ${tw`flex w-full absolute z-10 pin-t pin-l w-full px-8 py-8 bg-white shadow-lg appearance-none outline-none text-lg`};
    border: none;
    border-radius: 0.25rem;
    box-shadow: 0 32px 64px rgba(0,0,0,0.1);
    // padding: ${p => p.large && '2rem 1rem'};
    &::placeholder{
        ${tw`text-grey-light`};
    }
`
const CircleButton = styled.div`
    ${tw`absolute pin-r rounded-full bg-grey z-20`};
    top: -20%;
    height: 4rem;
    width: 4rem;
`
const StyledColorfulCircle = styled(ColorfulCircle)`
    ${tw`absolute z-20 cursor-pointer `};
    right: 1rem;
    top: 0;
    // right: ${p => p.large ? '1rem' : '-2%'};
    // top: ${ p => p.large ? 0 : '-1rem'};
`

const ErrorMessage = styled.p`
${ tw`w-full font-secondary ml-2 mt-4 text-red font-semibold tracking-wide`};
`

const EmailSubscribe = (props) => {

    const [email, setEmail] = useState("");

    const [formStatus, setFormStatus] = useState("default");
    const [errorMsg, setErrorMsg] = useState();
    //formStatus can be "default", "submitted", "empty".

    const form = useRef();

    const onFormSubmit = (e) => {

        e.preventDefault();
        if (email == null || email == "") {
            console.log("Email can't be empty!!");
            setFormStatus("fail");
            setErrorMsg("Do you like emptiness? Email can't be empty!");
        }
        else {
            addToMailchimp(email) // listFields are optional if you are only capturing the email address.
                .then(data => {
                    // I recommend setting data to React state
                    // but you can do whatever you want (including ignoring this `then()` altogether)
                    console.log(data)
                    if (data.result == 'success') {
                        console.log(data.msg);
                        setFormStatus("success");
                    }
                    else {
                        console.log('Sorry! Please try again!');
                        setFormStatus("fail");
                        setErrorMsg("Failed, please try again!")
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setFormStatus("fail");
                    setErrorMsg("Failed, please try again!")
                    // unnecessary because Mailchimp only ever
                    // returns a 200 status code
                });
        }
    };

    return (

        <Form ref={form} onSubmit={onFormSubmit}>
            {(!props.noLabel && formStatus != "success") && <Label>Subscribe to updates</Label>}
            {/* Form Status Submitted */}

            {formStatus != "success" &&

                <InputWrapper style={{ ...props.innerStyle }}>

                    <EmailInput
                        large={props.large}
                        type="email"
                        value={email}
                        placeholder="yourname@domain.something"
                        onChange={e => setEmail(e.target.value)}
                    />

                    {/* <div css={`${ tw`relative h-full w-full rounded-full overflow-hidden` } `}></div> */}
                    <StyledColorfulCircle
                        large={props.large}
                        height={100}
                        width={100}
                        onClick={() => { debugger; form.current.dispatchEvent(new Event('submit')) }} />
                </InputWrapper>
            }

            {formStatus == "fail" &&
                <ErrorMessage>{errorMsg}</ErrorMessage>
            }
            {/* {formStatus == "failed" && <ErrorMessage>Failed, please try again!</ErrorMessage>} */}

            {formStatus == "success" &&
                <div css={`${tw`w-full `}`}>
                    <img src={require('../../images/check.svg')} alt="Check mark" />
                    <h4>One last step...</h4>
                    <p css={`${tw`mt-4`}`}>
                        Please confirm your email from the confirmation email sent to:<br />
                        <strong>{email}</strong>
                    </p>
                </div>
            }


        </Form>
    );
}


export default EmailSubscribe;