import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import { GlobalStyle } from '../globals/styledComponents';
import * as Gs from '../globals/styledComponents';


import Layout from '../components/layout';
import Nav from '../components/Nav';
import Row from '../components/Row';
import EmailSubscribe from '../components/utils/EmailSubscribe'
import CustomLink from '../components/utils/CustomLink';


const LayoutWrapper = styled.div`
    ${tw`w-screen overflow-x-hidden`};
`
const SectionOne = styled.section`
    ${tw`min-h-screen flex items-center relative h-auto`};
`
const BgText = styled.p`
    ${tw`absolute text-6gr text-white font-primary font-black z-back tracking-tight opacity-10`};
    left: 5vh;
    top: 25vh;
    // border: 1px solid grey;
    -webkit-text-stroke: 2px grey;

`
const Section = styled.section`
    ${tw`relative z-10 flex items-center flex-wrap`};
    min-height: 60vh;
`
const Chapter = styled.h3`
    ${tw`text-gr font-primary font-semibold text-grey-light mt-4 ml-4`}
`

// const SocialLink = styled.li`
//     a{
//         ${tw`my-4 py-8 hover:bg-grey-light decoration-none text-grey font-semibold font-primary`};

//     }
// `

const ContributionsList = styled.ul`
    ${tw`list-reset m-0`};
    li{
        ${tw`my-4`}
    }
`

const SocialLink = styled.p`
     ${ tw`text-gr font-primary font-semibold`};
`

const product123 = () => (
    <Layout product123>
        <GlobalStyle />

        <SectionOne>
            <Row justifyEnd css={`${tw`items-start mt-44`}`}>
                <div css={`${tw`w-24 lg:flex hidden h-px bg-grey mt-12 opacity-50`}`}>
                </div>
                <div css={`${tw`w-full lg:w-gr2/3`}`}>
                    <h1 css={`${tw`font-primary font-black`}`}>Watch us <br />build products &amp; become an expert.</h1>
                    <div css={`${tw`lg:w-4/5`}`}>
                        <p css={`${tw`my-12`}`}>What do you do after you have an idea? Startups and product team forever worked in secrecy. We decided to change that and take everyone on a journey behind the screens</p>
                        <EmailSubscribe />
                    </div>
                </div>
            </Row>

            <BgText>#product123</BgText>

        </SectionOne>

        <Section css={`${tw`pt-44`}`}>
            <Row>
                <div css={`${tw`w-full lg:w-gr2/3`}`}>
                    <h2 css={`${tw``}`}>We are a product agency who decided to build some of our own products.</h2>
                    <p css={`${tw`my-8`}`}>Usually, we build for our clients.</p>
                </div>
            </Row>
        </Section>

        <Section css={`${tw`relative`}`}>
            <Row justifyEnd css={`${tw`relative z-10`}`}>
                <div css={`${tw`w-full lg:w-gr2/3 relative`}`}>
                    <h2 css={`${tw``}`}>Share the entire process with you.</h2>
                    <BgText css={`top: -7rem; left: -3rem;`}>&amp;</BgText>
                </div>
            </Row>
        </Section>


        <Section css={`${tw`bg-grey-lightest mx-6 md:mx-18 py-44`}`}>
            <Row>
                <div css={`${tw`lg:w-gr2/3`}`}>
                    <h2>With a hope that this knowledge will help anyone in need</h2>
                    <p css={`${tw`my-8`}`}>We'll try to share everything — the process, the workflow, and the decisions</p>
                </div>
                <div css={`${tw`w-full flex flex-wrap`}`}>
                    <div css={`${tw`w-full md:w-1/3 flex items-center my-4`}`}>

                        <img src={require('../images/zap.svg')} />
                        <Chapter>Ideation</Chapter>

                    </div>
                    <div css={`${tw`w-full md:w-1/3 flex items-center my-4`}`}>

                        <img src={require('../images/aperture.svg')} />
                        <Chapter>Strategizing</Chapter>

                    </div>
                    <div css={`${tw`w-full md:w-1/3 flex items-center my-4`}`}>

                        <img src={require('../images/pen-tool.svg')} />
                        <Chapter>Design</Chapter>

                    </div>
                    <div css={`${tw`w-full md:w-1/3 flex items-center my-4`}`}>

                        <img src={require('../images/code.svg')} />
                        <Chapter>Development</Chapter>

                    </div>
                    <div css={`${tw`w-full md:w-1/3 flex items-center my-4`}`}>

                        <img src={require('../images/send.svg')} />
                        <Chapter>Launch</Chapter>

                    </div>
                </div>
            </Row>
        </Section>

        <Section css={`${tw`py-44`}`}>

            <Row justifyEnd css={`${tw`items-start`}`}>
                <div css={`${tw`w-24 lg:flex hidden h-px bg-grey mt-12 opacity-50`}`}>
                </div>
                <div css={`${tw`w-full lg:w-gr2/3`}`}>
                    <h2 css={`${tw`font-primary font-black w-5/6`}`}>Daily Video Updates</h2>
                    <p css={`${tw`my-8 md:w-1/2`}`}>All you have to do is like / subscribe / follow us on your preferred social media</p>
                    <ul css={`${tw`w-full md:w-2/3 list-reset m-0 my-8`}`}>
                        <li>
                            <CustomLink external to="https://www.instagram.com/clearcutdesignstudio">
                                <SocialLink>Instagram</SocialLink>
                            </CustomLink>
                        </li>
                        <li>
                            <CustomLink external to="https://www.youtube.com/channel/UCd8YZa0seRj6I6Y35sjl0kw">
                                <SocialLink>Youtube</SocialLink>
                            </CustomLink>
                        </li>
                        <li>
                            <CustomLink external to="https://www.linkedin.com/company/clearcut-design/">
                                <SocialLink>LinkedIn</SocialLink>
                            </CustomLink>
                        </li>
                        <li>
                            <CustomLink external to="https://twitter.com/ClearcutAgency">
                                <SocialLink>Twitter</SocialLink>
                            </CustomLink>
                        </li>
                        <li>
                            <CustomLink external to="https://www.facebook.com/clearcutdesignstudio">
                                <SocialLink>Facebook</SocialLink>
                            </CustomLink>
                        </li>
                    </ul>
                </div>
            </Row>

        </Section>

        <Section>
            <Row>
                <div css={`${tw`lg:w-gr2/3`}`}>
                    <h2>A super rapid crash course that covers a lot of ground!</h2>
                </div>
            </Row>
        </Section>

        <Section>
            <Row justifyEnd>
                <div css={`${tw`w-full lg:w-gr2/3`}`}>
                    <h2>Better than many Startup Schools or expensive Bootcamps</h2>
                </div>
            </Row>
        </Section>

        <Section css={`
            border-radius: 3.236rem 3.236rem 0 0;
            ${tw`bg-grey-lightest pt-16 relative z-0`};
        `}>
            <Row>
                <div css={`${tw`w-gr2/3`}`}>
                    <Gs.QuotationText>
                        First Product
                    </Gs.QuotationText>
                    <h1>Unclient</h1>
                    <h3 css={`${tw`font-light tracking-tight my-2 text-grey-light`}`}>We'll start on Aug 5th</h3>
                </div>
            </Row>
            <Row justifyEnd>
                <div css={`${tw`w-full lg:w-1/2 relative z-20`}; transform: translate3d(0, 30px, 0)`}>
                    {/* <p>It's zero price, zero spam, zero bullshit!</p> */}
                    <EmailSubscribe large />
                </div>
            </Row>

        </Section>

        <Section css={`${tw`bg-black py-44 relative z-back`}`}>

            <Row>

                <div css={`${tw`w-full lg:w-gr1/3`}`}>

                    <Gs.QuotationText css={`${tw`text-grey-light`}`}>We're open for contributions. Write to us</Gs.QuotationText>

                    <ContributionsList>
                        <li>
                            <CustomLink external email isOnDark to="mailto:hello@clearcut.design?subject = Product123 Contribution ">
                                <p css={`${tw`text-gr`}`}>Growth Marketers</p>
                            </CustomLink>
                        </li>
                        <li>
                            <CustomLink external email isOnDark to="mailto:hello@clearcut.design?subject = Product123 Contribution, UXWriter">
                                <p css={`${tw`text-gr`}`}>UX Writer</p>
                            </CustomLink>
                        </li>
                        <li>
                            <CustomLink external email isOnDark to="mailto:hello@clearcut.design?subject = Product123 Contribution, UXWriter">
                                <p css={`${tw`text-gr`}`}>Illustrators</p>
                            </CustomLink>
                        </li>
                        <li>
                            <CustomLink external email isOnDark to="mailto:hello@clearcut.design?subject = Product123 Contribution, UXWriter">
                                <p css={`${tw`text-gr`}`}>UX Researchers</p>
                            </CustomLink>
                        </li>
                    </ContributionsList>


                </div>

            </Row>

        </Section>


    </Layout>
)

export default product123;



