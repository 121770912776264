/**
 * Layout component that has menu and nav bar.
 *
 */

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import tw from 'tailwind.macro';
import { useSpring } from 'react-spring';
import { Waypoint } from 'react-waypoint';

import Header from "./header";
import "./layout.css"
import Nav from './Nav';
import Menu from './utils/Menu'

import { GlobalStyle } from '../globals/styledComponents';

function Layout(props) {

  const [headerScrolled, setHeaderScrolled] = useState(false);
  const [isMenuActive, setMenuActive] = useState(false);
  const [st, setScrollTop] = useSpring(() => ({ y: 0 }));

  useEffect(() => {
    // Wrap the require in check for window
    if (typeof window !== `undefined`) {
      window.onscroll = function () {
        // debugger;
        let st = window.scrollY;
        console.log(`scrollTop: ${st}`);

        //This is for any Parallax effects
        setScrollTop({ y: st });

        //This is for NavBar hiding and appearing
      }
      // console.log(window.innerWidth);
    }


  });

  // const [headerScrolled, setHeaderScrolled] = useState(false);

  return (
    <div css={`${tw`max-w-screen overflow-x-hidden lg:overflow-x-visible relative z-0`}`}>
      <GlobalStyle />
      <link prefetch rel="stylesheet" href="https://use.typekit.net/htu5gmj.css" />

      <Nav
        product123={props.product123}
        darken={props.withDarkMenu || props.headerScrolled}
        isScrolled={(headerScrolled || props.headerScrolled) && !isMenuActive}
        handleMenuClick={() => setMenuActive(state => !state)}
        isOnMenu={isMenuActive}
      />
      <Menu active={isMenuActive}
        deactivate={() => setMenuActive(state => !state)}
      />
      <Waypoint
        scrollableAncestor={'window'}
        fireOnRapidScroll={true}
        // debug={true}
        onEnter={() => { console.log('waypoint entered'); setHeaderScrolled(false) }}
        onLeave={() => { console.log('waypoint left'); setHeaderScrolled(true) }}
      />

      {props.children}

    </div>
  );
}




Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
