import React, { useState, useEffect, useCallback, useRef } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from 'gatsby-image'
import styled from 'styled-components';
import tw from 'tailwind.macro';
import { useSpring, useTrail, animated, interpolate, useChain } from 'react-spring';
import { Waypoint } from 'react-waypoint';
import MagicGrid from 'magic-grid-react';
import Slide from 'react-reveal/Slide';

import * as Gs from '../globals/styledComponents';

import Layout from "../components/layout";
import Nav from '../components/Nav';
import Image from "../components/image"
import SEO from "../components/seo"
import Row from "../components/Row";

import Footer from '../components/footer'
import CustomLink from '../components/utils/CustomLink';


const SectionOne = styled.section`
    ${tw``};
`

const HeroImage = styled.div`
    ${tw`w-full flex my-14 bg-grey-light`};
    height: 60vh;
    border-radius: 0.809rem;

`
const StyledSection = styled.section`
    ${tw`py-36`};
`
export default function IndexPage(props) {

    return (
        <Layout>

            <SEO />

            {/* Main Content goes Here */}

            <main css={`${tw`relative overflow-x-hidden`}`}>

                <SectionOne>
                    <Row css={`${tw`items-center`}; margin-top: 33vh;`}>

                        <Gs.HeroHeading>
                            Regional News <br />
                            Global Glam
                        </Gs.HeroHeading>

                    </Row>
                    <Row stretch css={`${tw`mt-16`}`}>

                        <HeroImage />

                    </Row>
                </SectionOne>

                <StyledSection>

                    <Row>
                        <div css={`${tw`lg:w-4/5`}`}>
                            <Gs.QuotationText>
                                Aliqua enim cillum est laborum dolor anim sunt. Incididunt labore elit minim qui nostrud aliqua Lorem aliquip voluptate aliqua ea cupidatat id. Do labore in laborum irure do velit aliqua labore culpa ea duis aute. Mollit minim commodo ex est magna elit ex tempor incididunt culpa fugiat tempor cupidatat sit. Reprehenderit ullamco cupidatat sint reprehenderit excepteur exercitation enim elit nisi occaecat sit non.
                            </Gs.QuotationText>
                        </div>
                    </Row>

                </StyledSection>

                <StyledSection>

                    <Row css={`${tw`items-start`}`}>
                        <div css={`${tw`w-full md:w-1/2 my-4 md:my-0`}`}>

                            <div css={`${tw`flex items-center`}`}>
                                <div css={`${tw`h-1 w-16 bg-blue mr-2`}`}></div>
                                <h2>Brief</h2>
                            </div>

                        </div>
                        <div css={`${tw`w-full md:w-1/2 mt-12 md:mt-0`}`}>
                            <p>Nisi Lorem et sint nulla dolor laborum Lorem nulla ex mollit esse aliquip. Incididunt amet aute ut sunt esse pariatur dolor voluptate nulla quis velit veniam. Mollit aliquip pariatur culpa anim laborum fugiat magna voluptate. In quis voluptate consectetur consequat velit sit est officia eiusmod et exercitation mollit et Lorem. Ea exercitation voluptate ipsum culpa.</p>
                        </div>
                    </Row>

                </StyledSection>

                <StyledSection color={"hsla(234, 75, 95, 1)"}>

                    <Row css={`${tw`items-start`}`}>
                        <div css={`${tw`w-full md:w-1/2 my-4 md:my-0`}`}>

                            <div css={`${tw`flex items-center`}`}>
                                <div css={`${tw`h-1 w-16 bg-blue mr-2`}`}></div>
                                <h2>The <span css={`${tw`text-brand-blue`}`}>Result.</span></h2>
                            </div>

                        </div>
                        <div css={`${tw`w-full md:w-1/2 mt-12 md:mt-0`}`}>
                            <p>Nisi Lorem et sint nulla dolor laborum Lorem nulla ex mollit esse aliquip. Incididunt amet aute ut sunt esse pariatur dolor voluptate nulla quis velit veniam. Mollit aliquip pariatur culpa anim laborum fugiat magna voluptate. In quis voluptate consectetur consequat velit sit est officia eiusmod et exercitation mollit et Lorem. Ea exercitation voluptate ipsum culpa.</p>
                        </div>
                    </Row>

                    <Row>
                        <div css={`${tw`w-full`}`}>

                        </div>
                    </Row>

                </StyledSection>


            </main>

            {/* Main Content Ends Here */}


            <Footer />
        </Layout>
    )

}
