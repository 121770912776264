import React from "react";

const ColorfulCircle = props => (
    <svg width="192px" height="168px" viewBox="0 0 192 168" {...props}>
        <defs>
            <filter
                x="-40.3%"
                y="-40.3%"
                width="196.8%"
                height="196.8%"
                filterUnits="objectBoundingBox"
                id="a"
            >
                <feOffset
                    dx={-4}
                    dy={10}
                    in="SourceAlpha"
                    result="shadowOffsetOuter1"
                />
                <feGaussianBlur
                    stdDeviation={7}
                    in="shadowOffsetOuter1"
                    result="shadowBlurOuter1"
                />
                <feColorMatrix
                    values="0 0 0 0 0.129958506 0 0 0 0 0.159087137 0 0 0 0 0.410041494 0 0 0 0.21 0"
                    in="shadowBlurOuter1"
                    result="shadowMatrixOuter1"
                />
                <feMerge>
                    <feMergeNode in="shadowMatrixOuter1" />
                    <feMergeNode in="SourceGraphic" />
                </feMerge>
            </filter>
            <circle id="b" cx={62} cy={62} r={62} />
            <filter
                x="-12.9%"
                y="-12.9%"
                width="141.9%"
                height="141.9%"
                filterUnits="objectBoundingBox"
                id="c"
            >
                <feOffset
                    dx={10}
                    dy={10}
                    in="SourceAlpha"
                    result="shadowOffsetOuter1"
                />
                <feGaussianBlur
                    stdDeviation={7}
                    in="shadowOffsetOuter1"
                    result="shadowBlurOuter1"
                />
                <feColorMatrix
                    values="0 0 0 0 0.4 0 0 0 0 0 0 0 0 0 0.0541935484 0 0 0 0.2 0"
                    in="shadowBlurOuter1"
                />
            </filter>
        </defs>
        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
            <g
                filter="url(#a)"
                transform="translate(-1048 -5171) translate(118 5172) translate(948 3)"
            >
                <mask id="d" fill="#fff">
                    <use xlinkHref="#b" />
                </mask>
                <use fill="#000" filter="url(#c)" xlinkHref="#b" />
                <use fill="#D8D8D8" xlinkHref="#b" />
                <circle
                    fill="#3A47B7"
                    opacity={0.800000012}
                    mask="url(#d)"
                    cx={26}
                    cy={95}
                    r={62}
                />
                <circle
                    fill="#FF6479"
                    opacity={0.800000012}
                    mask="url(#d)"
                    cx={109.5}
                    cy={14.5}
                    r={109.5}
                />
            </g>
            <path
                d="M165.366 70.687H66.187a2.187 2.187 0 110-4.374h99.179l-13.119-11.48a2.187 2.187 0 112.881-3.292l17.5 15.313a2.187 2.187 0 010 3.292l-17.5 15.313a2.188 2.188 0 01-2.881-3.293l13.119-11.479z"
                fill="#060606"
                fillRule="nonzero"
                transform="translate(-1048 -5171) translate(118 5172) translate(948 3)"
            />
        </g>
    </svg>
);

export default ColorfulCircle;
